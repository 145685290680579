import React from "react";
import PropTypes from "prop-types";
import PostLink from "../components/BlogContent/post-link";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";

// Components
import { Link, graphql } from "gatsby";

// Utilities
import kebabCase from "lodash/kebabCase";

const Projects = ({ data }) => {
  const { t } = useTranslation();
  const { edges } = data.postData;
  const group = data.tagData.group;
  const arrayOfImages = [];

  edges.map((galleryImage) => arrayOfImages.push(galleryImage));

  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Projekte")}
        homePageText="Home"
        homePageUrl="/"
        activePageText="Projekte"
      />
      <div>
        <div className="widget-area pb-70">
          <div className="container widget widget_tag_cloud">
            <h3 className="widget-title">{t("Tags")}</h3>

            <div className="tagcloud">
              {group.map((tag) => (
                <Link
                  key={Math.random()}
                  to={`/projects/${kebabCase(tag.fieldValue)}/`}
                >
                  <span className="post-count">
                    {" "}
                    {tag.fieldValue} ({tag.totalCount}){" "}
                  </span>
                </Link>
              ))}
              <Link to="/projects">Alle</Link>
            </div>
          </div>
        </div>
        <div className="blog-area">
          <div className="container">
            <div className="row">
              {edges.map(({ node }) => (
                <PostLink key={node.id} post={node} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

Projects.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Projects;

export const pageQuery = graphql`
  query ($tag: String, $language: String!) {
    tagData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/markdown-pages/)/" }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    allTagData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/meters/)/" }
      }
    ) {
      totalCount
    }
    postData: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] }, language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/markdown-pages/)/" }
      }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(locale: $language, formatString: "MMMM DD, YYYY")
            title
            slug
            images {
              mainImage {
                childImageSharp {
                  gatsbyImageData(width: 800, layout: CONSTRAINED)
                }
              }
              galleryImages {
                childImageSharp {
                  gatsbyImageData(width: 800, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
